@use 'material-theme';
@use 'libs/styles/responsive';

$linkedin-color: #0a66c2;

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

body {
  main {
    // TODO(accredishare_widget): Review these styles once Accredishare is released
    .sharing-widget,
    .sticky-share:not(.all-social-networks) {
      color: #fff !important; // Required to override the default black text;
      background-color: #37397a !important; // Required to override the default white background

      .quick-actions-wrapper,
      .wrapper.right {
        button.mat-stroked-button:not(.linkedin-add) {
          color: var(--primary-color);
          background-color: #fff;
          border-radius: 4px !important; // Required to override theme default button radius;
        }
      }

      button.linkedin-add {
        padding: 7px;
        color: $linkedin-color !important; // Required to override the default text color;
        background-color: #fff;
        border-radius: 4px !important; // Required to override theme default button radius;

        &:hover {
          color: $linkedin-color;
          background-color: #fff !important; // Required to override the default background;
          opacity: 0.9;
        }
      }

      button[mat-flat-button].smartresume-add {
        color: $linkedin-color;
        background-color: white;

        &:hover {
          color: $linkedin-color;
          background-color: #fff;
          opacity: 0.9;
        }
      }

      rp-accredishare-bar {
        .btn-social-network,
        .btn-more-options {
          background-color: #fff !important; // Required to not have to specify this in each social network class;
          border-radius: var(--theme-border-radius);

          &:focus {
            outline-color: var(
              --theme-outline-color
            ) !important; // Required to not have to specify this in each social network class;
          }

          accredible-icon {
            color: #000;
          }

          // Facebook
          &.facebook {
            accredible-icon {
              color: #506bb1;
            }
          }

          // Twitter
          &.x {
            accredible-icon {
              color: #000000;
            }
          }

          // Whatsapp
          &.whatsapp {
            accredible-icon {
              color: #02a601;
            }
          }

          // LinkedIn
          &.linkedin {
            accredible-icon {
              color: $linkedin-color;
            }
          }
        }
      }
    }

    .referral-widget {
      button {
        color: var(--accent-color);
      }
    }

    rp-group-list-item {
      .course-recommendation {
        .appearance-wrapper {
          background-color: #e9f8f9 !important; // Matching the design of credential.net
        }

        .details {
          .organization {
            color: #63647a;
          }
        }
      }

      .show-on-mobile,
      .course-recommendation {
        .skills {
          accredible-chip {
            background-color: #e9f8f9;

            > .mat-tooltip-trigger {
              padding: 0 10px;
            }
          }

          > span:first-child {
            color: #63647a;
          }
        }
      }
    }

    rp-recommended-credentials-widget {
      .recommended-credentials-widget:first-child {
        border-radius: 10px;

        .title-and-subtitle {
          h2 {
            font-size: 20px;
            font-weight: 700;
            color: #141418;
          }

          div {
            font-size: 16px;
            color: #63647a;
          }
        }
      }
    }

    .widget.under-construction {
      background: linear-gradient(
        270deg,
        #ecedf5 0%,
        rgba(236, 237, 245, 0) 100%
      ) !important; // Required to override widget default background

      .title {
        color: var(--primary-color);
      }
    }

    .widget.discover-coursefinder {
      background: linear-gradient(
        360deg,
        #fdf8ee 51.67%,
        rgba(253, 248, 238, 0) 100%
      ) !important; // Required to override widget default background

      .title {
        color: var(--primary-color);
      }
    }
  }

  #main-content.accounts-app-root-container {
    accredible-banner-panel {
      .main-background {
        background-color: #ffffff;
      }

      .content {
        .main-content {
          background-color: #ffffff;
        }
      }
    }

    acs-settings-box {
      acs-settings-panel {
        accredible-text-input {
          input.mat-input-element {
            @include placeholder(#63647a);
          }
        }
      }

      acs-directory-info-completion {
        a {
          color: #454798;
        }
      }
    }

    %updated-mat-checkbox-ripple {
      .mat-checkbox-ripple {
        top: calc(50% - 11.75px);
        left: calc(50% - 11.75px);
        width: 23px;
        height: 23px;
        border: 2px solid;
        border-radius: calc(var(--theme-border-radius) + 2px);

        .mat-ripple-element {
          background: transparent;
        }
      }
    }

    .mat-checkbox {
      &.cdk-focused,
      &.cdk-keyboard-focused,
      .mat-checkbox-inner-container:hover {
        @extend %updated-mat-checkbox-ripple;
      }
    }
  }

  .acc-dialog {
    .btn-choose-file {
      text-decoration: underline;
    }
  }

  .linked-emails-dialog {
    .add-email-panel {
      .mat-caption {
        font-weight: 400;

        a {
          text-decoration: underline;
          color: var(--primary-color);
        }
      }
    }
  }

  label.mat-slide-toggle-label .mat-slide-toggle-bar {
    background-color: #4f5062;
  }

  .mat-slide-toggle.mat-checked {
    label.mat-slide-toggle-label .mat-slide-toggle-bar {
      background-color: #afe5e9;
    }

    .mat-slide-toggle-thumb {
      background-color: #176d72;
    }
  }

  .mat-radio-button {
    &.cdk-focused,
    &.cdk-keyboard-focused,
    .mat-radio-container:hover {
      .mat-radio-ripple {
        position: absolute;
        z-index: 1;
        top: calc(50% - 11px);
        left: calc(50% - 11px);
        width: 22px;
        height: 22px;
        border: 3px solid;
        border-radius: 50%;
        pointer-events: none;

        .mat-ripple-element {
          background: transparent;
        }
      }
    }
  }

  .mat-form-field .mat-input-element,
  .multi-select-input-wrapper:not(.input-focused):not(:hover),
  accredible-native-date-picker input {
    border-color: #828395;
  }

  accredible-select-input {
    mat-form-field.mat-form-field:not(.mat-focused) {
      .mat-form-field-wrapper .mat-form-field-label-wrapper {
        .mat-form-field-label mat-label {
          color: #63647a;
        }
      }
    }
  }

  // Change outline to black for better contrast
  .btn-linkedin-profile,
  .btn-copy-to-clipboard {
    &.mat-button-base.cdk-keyboard-focused:before {
      border: 4px solid #000000;
    }
  }
}
