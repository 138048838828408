@use 'responsive';

body {
  font-family: "Source Sans Pro", sans-serif;

  .card {
    margin: 15px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    overflow: hidden;

    @include responsive.media-gt(lg) {
      margin: 15px 30px;
    }

    h2, p {
      margin-bottom: 16px;
    }

    p {
      color: #696b88;
      font-size: 16px;
      line-height: 24px;
    }

    h2 {
      color: #454798;
    }

    .content-padded {
      padding: 24px 32px;
    }
  }

  // Remove arrows from input type number
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
